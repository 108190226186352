<template>
	<v-sheet color="transparent" class="pa-4">
		<p class="text-right">
			<Import collection="departments" defaultFields="name"></Import>
		</p>
		<v-card flat color="secondary">
			<v-data-table :headers="headers" :items="items">
				<template v-slot:item.section="{ item }">
					<span v-if="item.section">{{sections[item.section] ? sections[item.section].name : "" }}</span>
				</template>				
				<template v-slot:item.button="{ item }">
					<ListButtons :id="item.id" collection="departments"></ListButtons>
				</template>
			</v-data-table>			
		</v-card>
		<Export :rows="items"></Export>

		<p class="text-right">
			<v-btn color="primary" to="/admin/departments/new">{{mwtr.NewDepartmentNavItem}}</v-btn>
		</p>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import ListButtons from "@/components/admin/lists/ListButtons"
	import Import from "@/components/admin/panels/Import"
	import Export from "@/components/admin/panels/Export"
	export default {
		name: "Departments", 
		props: {
			
		},
		data: () => {
			return {
				search: ""
			}
		},
		components: {
			ListButtons,
			Import,
			Export
		},
		computed: {
			...Vuex.mapState({
				sections: state => state.sections,
				departments: state => state.departments
			}), 
			headers(){
				return [
					{value: "name", text: "Name"},
					{value: "section", text: "Section"},
					{value: "button", text: " "},
				]
			}, 
			items(){
				var departments = Object.values(this.departments)
				return departments;
			}
		},
		// methods: {
		// }
		created(){
			this.$store.dispatch("departments/fetchAll")
			this.$store.dispatch("sections/fetchAll")
      }

	}
// </script>"